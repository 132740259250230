<template>
  <div>
    <h1 class="is-size-2">Spenden Items</h1>
    <b-table
      :data="data"
      :per-page="perPage"
      :current-page="page"
      :loading="isLoading"
      :total="totalEmployees"
      paginated
      @page-change="changePage"
      @sort="sortEmployees"
    >
      <b-table-column field="img" label="Icon" sortable v-slot="props">
        <img :src="props.row.img" style="max-width: 3rem" />
      </b-table-column>
      <b-table-column field="name" label="Bezeichnung" sortable v-slot="props">
        {{ props.row.name }}
      </b-table-column>
      <b-table-column
        field="coins"
        label="Wert in Coins"
        sortable
        v-slot="props"
      >
        {{ props.row.coins }}
      </b-table-column>
      <b-table-column field="favorite" label="" numeric v-slot="props">
        <b-button size="is-small" @click="showDonationTypeDetails(props.row.id)"
          ><b-icon pack="fas" icon="eye"
        /></b-button>
      </b-table-column>
      <template slot="bottom-left"> Gesamt: {{ totalDonationTypes }} </template>
    </b-table>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
  data() {
    return {
      data: [],
      totalDonationTypes: 0,
      page: 1,
      perPage: 10,
      isLoading: true,
    };
  },
  methods: {
    init() {
      this.loadDonationTypes();
    },
    showDonationTypeDetails(id) {
      console.log("details", id);
      this.$router.push({ name: "donationtypedetail", params: { id: id } });
    },
    loadDonationTypes() {
      this.isLoading = true;

      this.$store.getters.axios
        .get(this.$store.getters.apiUrl + "/donationtypes")
        .then((response) => {
          let data = response.data;

          this.data = data;
          this.totalDonationTypes = data.length;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style></style>
