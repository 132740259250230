<template>
  <div>
    <div class="columns">
      <div class="column is-2">
        <img :src="donationType.img" />
      </div>
      <div class="column">
        <b-field label="Bezeichnung">
          <b-input v-model="donationType.name" :disabled="isLoading"></b-input>
        </b-field>
        <b-field label="Wert in Coins">
          <b-numberinput
            v-model="donationType.coins"
            :disabled="isLoading"
          ></b-numberinput>
        </b-field>
      </div>
    </div>
    <b-button :loading="isLoading" @click="updateDonationType"
      >Speichern</b-button
    >
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
  data() {
    return {
      id: this.$route.params.id,
      donationType: {},
      isLoading: true,
    };
  },
  methods: {
    init() {
      this.isLoading = true;
      this.$store.getters.axios
        .get(this.$store.getters.apiUrl + "/donationtypes/" + this.id)
        .then((response) => {
          this.donationType = response.data;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    updateDonationType() {
      this.isLoading = true;
      this.$store.getters.axios
        .put(
          this.$store.getters.apiUrl + "/donationtypes/" + this.id,
          this.donationType
        )
        .then((response) => {
          console.log(response);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style></style>
