<template>
 <b-navbar type="is-black" wrapper-class="container">
        <template #brand>
            <b-navbar-item tag="router-link" :to="{ path: '/' }">
                JulsBrickCity | TikTok LIVE Dashboard
            </b-navbar-item>
        </template>
        <template #start>
            <router-link tag="b-navbar-item" :to="{ path: '/donationtypes'}" v-if="$store.getters.isAdmin">
                Spenden Items
            </router-link>
            <router-link tag="b-navbar-item" :to="{ path: '/donations'}" v-if="$store.getters.isAdmin">
                Spenden
            </router-link>
        </template>
        <template #end>
            <router-link :to="{ path: '/login' }" tag="b-navbar-item" v-if="$store.getters.user != null">
                Abmelden
            </router-link>
        </template>
    </b-navbar>
</template>

<script>

export default {
  name: 'App',
  components: {

  }
}
</script>

<style>

</style>
