<template>
  <div>
    <div class="card">
      <header class="card-header">
        <p class="card-header-title">
          <b-icon pack="fas" icon="gift" />&nbsp;LIVE donations
        </p>
        <span class="card-header-icon" aria-label="more options">
          <b-tag type="is-success" icon-pack="fas" icon="play">LIVE</b-tag>
        </span>
      </header>
      <div
        class="card-content donations-window"
        style="
          max-height: 50vh;
          min-height: 50vh;
          overflow-y: auto;
          overflow-x: hidden;
        "
      >
        <table class="table is-fullwidth">
          <thead>
            <tr>
              <th>User</th>
              <th class="has-text-right">Anzahl</th>
              <th class="has-text-right">Wert in Coins</th>
              <th class="has-text-right">Item</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(donation, index) in $store.getters.donations"
              :key="index"
            >
              <td>{{ donation.nickname }}</td>
              <td class="has-text-right">{{ donation.amount }}</td>
              <td class="has-text-right">
                {{
                  Number(
                    donation.amount * donation.donation_type.coins
                  ).toFixed(0)
                }}
              </td>
              <td :title="donation.donation_type.name">
                <img
                  :title="donation.donation_type.name"
                  style="max-width: 3rem"
                  class="is-pulled-right"
                  :src="donation.donation_type.img"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Chat",
  methods: {},
  data() {
    return {};
  },
  watch: {
    "$store.getters.donations": function () {
      var element = document.getElementsByClassName("donations-window")[0];
      element.scrollTop = element.scrollHeight;
    },
  },
};
</script>

<style></style>
