import Vue from 'vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'
import App from './App.vue'
import Buefy from 'buefy'
import { store } from "./store/store.js";
import Home from "./components/Home.vue";
import Login from "./components/Login.vue";
import DonationTypes from "./components/DonationTypes.vue";
import DonationTypeDetail from "./components/DonationTypeDetail.vue";
import 'buefy/dist/buefy.css'
import VueSocketIO from 'vue-socket.io'
import SocketIO from 'socket.io-client'

Vue.use(Buefy)

Vue.use(Vuex)
Vue.use(VueRouter)

Vue.config.productionTip = false

export const SocketInstance = SocketIO(process.env.VUE_APP_SOCKET_BACKEND_URL);

Vue.use(
  new VueSocketIO({
    debug: false,
    connection: SocketInstance,
    vuex: { store, actionPrefix: "SOCKET_", mutationPrefix: "SOCKET_" },
    options: { path: "/api/" }
  })
);

export const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/home",
      name: "home",
      component: Home,
    },
    {
      path: "/donationtypes/:id",
      name: "donationtypedetail",
      component: DonationTypeDetail,
    }, 
    {
      path: "/donationtypes",
      name: "donationtypes",
      component: DonationTypes,
    },      
    {
      path: "/login",
      name: "login",
      component: Login,
    },    
    {
      path: "*",
      component: Home,
    },
  ],
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ["/login"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("user");

  if (authRequired && !loggedIn) {
    return next("/login");
  }

  next();
});


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
