<template>
  <div>
    <div class="card">
      <header class="card-header">
        <p class="card-header-title">
          <b-icon pack="far" icon="comments" />&nbsp;LIVE chat
        </p>
        <span class="card-header-icon" aria-label="more options">
          <b-tag type="is-success" icon-pack="fas" icon="play">LIVE</b-tag>
        </span>
      </header>
      <div
        class="card-content chat-window"
        style="
          max-height: 50vh;
          min-height: 50vh;
          overflow-y: auto;
          overflow-x: hidden;
        "
      >
        <div
          class="chat-message"
          v-for="(message, index) in $store.getters.chatMessages"
          :key="index"
        >
          <span class="nickname">{{ message.nickname }}:</span>
          {{ message.message }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Chat",
  methods: {},
  data() {
    return {};
  },
  watch: {
    "$store.getters.chatMessages": function () {
      var element = document.getElementsByClassName("chat-window")[0];
      element.scrollTop = element.scrollHeight;
    },
  },
};
</script>

<style>
.chat-message {
  margin-top: 5px;
}

.nickname {
  font-weight: bold;
}

.avatar {
  margin-right: 10px;
  width: 1rem;
}

.avatar > img {
  max-width: 1rem;
}
</style>
