<template>
  <div>    
    <div class="columns">
      <div class="column">        
        <tile icon="comments" title="Nachrichten" :value="Number($store.getters.totalMessages).toLocaleString()" />
      </div>
      <div class="column">
        <tile icon="coins" title="Coins" :value="Number(totalCoins).toLocaleString()" />
      </div>
      <div class="column">
        <tile icon="gift" title="Spenden" :value="Number(totalDonations).toLocaleString()" />
      </div>      
    </div>
    <div class="columns">
      <div class="column is-6">
        <chat />
      </div>
      <div class="column is-6">
        <donations />
      </div>
    </div>        
  </div>
</template>

<script>
import Chat from './Chat.vue';
import Donations from './Donations.vue';
import Tile from './Tile.vue';

export default {
  name: "Home",
  components: { Chat, Donations, Tile },
  methods: {},
  computed: {
    totalDonations(){
      return this.$store.getters.donations.length;
    },
    totalCoins() {
      let totalCoins = 0;

      this.$store.getters.donations.forEach((item) => {
        totalCoins = totalCoins + item.amount * item.donation_type.coins;
      });

      return totalCoins;
    },
  }
}
</script>

<style></style>
